<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <nav class="responsive-nav -mb-0.5 lg:pl-2">
            <div class="flex justify-between flex-col-reverse lg:flex-row">
              <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
                <ul  uk-switcher="connect: #comment-tab; animation: uk-animation-fade">
                  <li><a href="#">Tất cả<span>{{ allComments.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Chờ kiểm duyệt<span>{{ waitingComments.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Chưa xử lý<span>{{ nonProcessedComments.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Đã xử lý<span>{{ doneComments.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Từ chối<span>{{ rejectedComments.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Bị xóa<span>{{ trashedComments.paginatorInfo.total }}</span></a></li>
                </ul>
              </nav>
            </div>
          </nav>

          <form class="mt-4" v-on:submit.prevent="updateBatchComment">
            <div class="flex space-x-4">
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <option value="done">Đã xử lý yêu cầu</option>
                <option value="reject">Từ chối yêu cầu</option>
                <option value="approve">Kiểm duyệt xong</option>
                <option value="delete">Xóa bình luận</option>
              </select>
              <button :disabled="!selectedCommentIds.length || !action" type="submit" class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện <span v-if="selectedCommentIds.length">({{ selectedCommentIds.length }})</span></button>
            </div>
          </form>

          <div class="uk-switcher lg:mt-8 mt-4" id="comment-tab">
            <div class="allComments">
              <table class="w-full">
                <tbody class="divide-y" v-if="allComments.data && allComments.data.length">
                <comment-item unique="allComments" v-on:checkedComment="handleCheckedComment" :comment="comment"
                           v-for="comment in allComments.data" :key="'allComments' + comment.id"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="allComments.paginatorInfo.lastPage > 1"
                    v-model="allComments.paginatorInfo.currentPage"
                    :pages="allComments.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadAllComments"
                />
              </div>
            </div>
            <div class="waitingComments">
              <table class="w-full">
                <tbody class="divide-y" v-if="waitingComments.data && waitingComments.data.length">
                <comment-item unique="waitingComments" v-on:checkedComment="handleCheckedComment" :comment="comment"
                              v-for="comment in waitingComments.data" :key="'waitingComments' + comment.id"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="waitingComments.paginatorInfo.lastPage > 1"
                    v-model="waitingComments.paginatorInfo.currentPage"
                    :pages="waitingComments.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadWaitingComments"
                />
              </div>
            </div>
            <div class="nonProcessedComments">
              <table class="w-full">
                <tbody class="divide-y" v-if="nonProcessedComments.data && nonProcessedComments.data.length">
                <comment-item unique="nonProcessedComments" v-on:checkedComment="handleCheckedComment" :comment="comment"
                              v-for="comment in nonProcessedComments.data" :key="'nonProcessedComments' + comment.id"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="nonProcessedComments.paginatorInfo.lastPage > 1"
                    v-model="nonProcessedComments.paginatorInfo.currentPage"
                    :pages="nonProcessedComments.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadNonProcessedComments"
                />
              </div>
            </div>
            <div class="doneComments">
              <table class="w-full">
                <tbody class="divide-y" v-if="doneComments.data && doneComments.data.length">
                <comment-item unique="doneComments" v-on:checkedComment="handleCheckedComment" :comment="comment"
                              v-for="comment in doneComments.data" :key="'doneComments' + comment.id"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="doneComments.paginatorInfo.lastPage > 1"
                    v-model="doneComments.paginatorInfo.currentPage"
                    :pages="doneComments.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadDoneComments"
                />
              </div>
            </div>
            <div class="rejectedComments">
              <table class="w-full">
                <tbody class="divide-y" v-if="rejectedComments.data && rejectedComments.data.length">
                <comment-item unique="rejectedComments" v-on:checkedComment="handleCheckedComment" :comment="comment"
                              v-for="comment in rejectedComments.data" :key="'rejectedComments' + comment.id"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="rejectedComments.paginatorInfo.lastPage > 1"
                    v-model="rejectedComments.paginatorInfo.currentPage"
                    :pages="rejectedComments.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadRejectedComments"
                />
              </div>
            </div>
            <div class="trashedComments">
              <table class="w-full">
                <tbody class="divide-y" v-if="trashedComments.data && trashedComments.data.length">
                <comment-item unique="trashedComments" v-on:checkedComment="handleCheckedComment" :comment="comment"
                              v-for="comment in trashedComments.data" :key="'trashedComments' + comment.id"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="trashedComments.paginatorInfo.lastPage > 1"
                    v-model="trashedComments.paginatorInfo.currentPage"
                    :pages="trashedComments.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadTrashedComments"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import CommentItem from "./CommentItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {canManageComment, changePageTitle} from "../../../core/services/utils.service";

export default {
  name: "AdminCommentList",
  components: {CommentItem, VPagination},
  data() {
    return {
      selectedCommentIds: [],
      action: "",
      allComments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      doneComments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      rejectedComments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      nonProcessedComments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      waitingComments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      trashedComments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadAllComments() {
      let query = `query($page: Int) {
        comments(first:10, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            is_request
            status
            request_status
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
              ... on Upload {
                id
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.allComments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.comments) {
              this.allComments = data.data.comments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadNonProcessedComments() {
      let query = `query($page: Int) {
        comments(first:10, where: {AND: [{column: "status", value: 1},{column: "is_request", value: 1},{column: "request_status", value: null}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            is_request
            status
            request_status
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.nonProcessedComments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.comments) {
              this.nonProcessedComments = data.data.comments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadDoneComments() {
      let query = `query($page: Int) {
        comments(first:10, where: {AND: [{column: "status", value: 1},{column: "is_request", value: 1},{column: "request_status", value: "done"}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            is_request
            status
            request_status
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.doneComments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.comments) {
              this.doneComments = data.data.comments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadRejectedComments() {
      let query = `query($page: Int) {
        comments(first:10, where: {AND: [{column: "status", value: 1},{column: "is_request", value: 1},{column: "request_status", value: "rejected"}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            is_request
            status
            request_status
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.rejectedComments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.comments) {
              this.rejectedComments = data.data.comments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadWaitingComments() {
      let query = `query($page: Int) {
        allComments(first:10, where: {AND: [{column: "status", value: 2}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            is_request
            status
            request_status
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.waitingComments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.allComments) {
              this.waitingComments = data.data.allComments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadTrashedComments() {
      let query = `query($page: Int) {
        allComments(trashed: ONLY, first:10, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            deleted_at
            is_request
            status
            request_status
            user {
              id
              username
              avatar {
                url
              }
            }
            deleter {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.trashedComments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.allComments) {
              this.trashedComments = data.data.allComments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateBatchComment() {
      if (!this.selectedCommentIds.length || !this.action) {
        return;
      }

      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID!], $update: Mixed!) {
          updateBatchComment(ids: $ids, update: $update)
        }`;

        let update = {};
        const dateObj = new Date();
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const date = dateObj.getDate();

        switch (this.action) {
          case "done":
            update["request_status"] = "done";
            break;
          case "reject":
            update["request_status"] = "rejected";
            break;
          case "approve":
            update["status"] = 1;
            break;
          case "delete":
            update["deleted_at"] = `${year}-${month}-${date} 00:00:00`;
            break;
        }

        ApiService.graphql(query, {ids: this.selectedCommentIds, update: update})
            .then(({data}) => {
              if (data.data && data.data.updateBatchComment) {
                this.selectedCommentIds = [];
                this.reRender();
              } else {
                alert(data.errors[0].message);
              }
            })
            .catch((response) => {
              alert(response.message);
            });
      }
    },
    handleCheckedComment(comment, isChecked) {
      if (isChecked) {
        if (!this.selectedCommentIds.includes(comment.id)) {
          this.selectedCommentIds.push(comment.id);
        }
      } else {
        this.selectedCommentIds = this.selectedCommentIds.filter((item) => {
          return item !== comment.id;
        });
      }
    },
    reRender() {
      this.loadAllComments();
      this.loadNonProcessedComments();
      this.loadDoneComments();
      this.loadRejectedComments();
      this.loadWaitingComments();
      this.loadTrashedComments();
    }
  },
  mounted() {
    if (!canManageComment()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý bình luận");
  }
}
</script>
